<template>
  <div class="wrapper">
    <PostList
      :posts="posts"
      :stories="stories"
      :currentCustomer="getCurrentCustomer"
      :isShowSwitch="false"
      :isShowCloseIcon="false"
      :postListType="'narrow short'"
      isSmall
      isShowComment
      isShowEditIcon
      isShowMakeChangesButton
      @postDeletedSuccessfully="deletePostHandler"
      isShowTopActions
      isShowDateCreation
      isShowPublishTime
      isCanEditPublishDate
      headerTitle="Archived"
      @updateSchedule="updateScheduleHandler"
      isSortedByNewestScheduledForStr
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import PostList from "@/components/createPost/PostList";
import TextCreation from "@/models/TextForm";

export default {
  name: "Archive",
  data: () => ({
    posts: [],
    stories: [],
  }),
  components: {
    PostList,
  },
  async created() {
    await this.getPosts();
  },
  computed: {
    ...mapGetters("clientSelection", ["getCurrentCustomer"]),
  },
  methods: {
    ...mapActions("createPost", ["fetchPosts"]),
    async getPosts() {
      const data = await this.fetchPosts({
        routeName: "archive",
        customerId: this.getCurrentCustomer.id,
        limit: 100,
      });
      this.posts = [];
      this.stories = [];
      data.forEach((item) => {
        item.type === "post" ? this.posts.push(item) : this.stories.push(item);
      });
      this.$emit("updateAmountPost");
    },
    deletePostHandler() {
      this.getPosts();
      this.$emit("updateAmountPost");
    },
    updateScheduleHandler(postData) {
      let searchPost =
        this.posts.find((post) => post.id === postData.postId) ||
        this.stories.find((post) => post.id === postData.postId);
      searchPost
        ? (searchPost.scheduledForStr = postData.scheduledForStr)
        : null;
    },
  },
};
</script>
